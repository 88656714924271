import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IKeyValuePair } from 'src/app/core/models/api/core';
import { IPolicyReplacerFileItem } from 'src/app/core/models/api/fleets';
import { IPolicySearchResult } from 'src/app/core/models/api/policies';
import { ICollectionManager } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class PolicyReplacerViewModel {
  private _form: FormGroup;
  private _rootPolicies: Array<IPolicySearchResult> = [];
  private _collectionManagers: Array<ICollectionManager> = [];
  private _paymentTypes: Array<IMasterValue> = [];
  private _policyDurations: Array<IMasterValue> = [];
  private _transferModes: Array<IKeyValuePair<string, string>> = [];
  private _holderReferences: Array<IKeyValuePair<string, string>> = [];
  private _insuredReferences: Array<IKeyValuePair<string, string>> = [];

  private _filteredRootPolicies: Observable<Array<IPolicySearchResult>>;
  private _filteredCollectionManagers: Observable<Array<ICollectionManager>>;

  readonly C_TARGET_ROOT_POLICY_ID = 'targetRootPolicyId';
  readonly C_TRANSFER_MODE_ID = 'transferModeId';
  readonly C_CANCEL_WITHOUT_EFFECT = 'cancelWithoutEffect';
  readonly C_HOLDER_REFERENCE_ID = 'holderReferenceId';
  readonly C_INSURED_REFERENCE_ID = 'insuredReferenceId';
  readonly C_PAYMENT_TYPE_ID = 'paymentTypeId';
  readonly C_DURATION_ID = 'durationId';
  readonly C_COLLECTION_MANAGER_ID = 'collectionManagerId';
  readonly C_FILE = 'file';

  constructor() {
    this._form = new FormGroup({
      [this.C_TARGET_ROOT_POLICY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.rootPolicies, 'policyId')]),
      [this.C_TRANSFER_MODE_ID]: new FormControl(null, [Validators.required]),
      [this.C_CANCEL_WITHOUT_EFFECT]: new FormControl(false, []),
      [this.C_HOLDER_REFERENCE_ID]: new FormControl(null, [Validators.required]),
      [this.C_INSURED_REFERENCE_ID]: new FormControl(null, []),
      [this.C_PAYMENT_TYPE_ID]: new FormControl(null, []),
      [this.C_DURATION_ID]: new FormControl(null, [Validators.required]),
      [this.C_COLLECTION_MANAGER_ID]: new FormControl(null, [forbiddenNamesValidator(() => this.collectionManagers, 'collectionManagerId')]),
      [this.C_FILE]: new FormControl(null, [Validators.required])
    });

    this._filteredRootPolicies = this.getControl(this.C_TARGET_ROOT_POLICY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterRootPolicies(value || ''))
    );

    this._filteredCollectionManagers = this.getControl(this.C_COLLECTION_MANAGER_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterCollectionManagers(value || ''))
    );
  }

  get rootPolicies(): Array<IPolicySearchResult> {
    return this._rootPolicies;
  }

  set rootPolicies(value: Array<IPolicySearchResult>) {
    this._rootPolicies = value;
  }

  get collectionManagers(): Array<ICollectionManager> {
    return this._collectionManagers;
  }

  set collectionManagers(collectionManagers: Array<ICollectionManager>) {
    this._collectionManagers = collectionManagers;
  }

  get paymentTypes(): Array<IMasterValue> {
    return this._paymentTypes;
  }

  set paymentTypes(paymentTypes: Array<IMasterValue>) {
    this._paymentTypes = paymentTypes;
  }

  get policyDurations(): Array<IMasterValue> {
    return this._policyDurations;
  }

  set policyDurations(value: Array<IMasterValue>) {
    this._policyDurations = value;
  }

  get transferModes(): Array<IKeyValuePair<string, string>> {
    return this._transferModes;
  }

  set transferModes(value: Array<IKeyValuePair<string, string>>) {
    this._transferModes = value;
  }

  get holderReferences(): Array<IKeyValuePair<string, string>> {
    return this._holderReferences;
  }

  set holderReferences(value: Array<IKeyValuePair<string, string>>) {
    this._holderReferences = value;
  }

  get insuredReferences(): Array<IKeyValuePair<string, string>> {
    return this._insuredReferences;
  }

  set insuredReferences(value: Array<IKeyValuePair<string, string>>) {
    this._insuredReferences = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  /** OPERATION */

  get targetRootPolicyId(): string | null {
    return this.getControl(this.C_TARGET_ROOT_POLICY_ID).value;
  }

  set targetRootPolicyId(value: string | null) {
    this.getControl(this.C_TARGET_ROOT_POLICY_ID).setValue(value);
  }

  get transferModeId(): string | null {
    return this.getControl(this.C_TRANSFER_MODE_ID).value;
  }

  set transferModeId(value: string | null) {
    this.getControl(this.C_TRANSFER_MODE_ID).setValue(value);
  }

  get cancelWithoutEffect(): boolean {
    return this.getControl(this.C_CANCEL_WITHOUT_EFFECT).value;
  }

  set cancelWithoutEffect(value: boolean) {
    this.getControl(this.C_CANCEL_WITHOUT_EFFECT).setValue(value);
  }

  get holderReferenceId(): string | null {
    return this.getControl(this.C_HOLDER_REFERENCE_ID).value;
  }

  set holderReferenceId(value: string | null) {
    this.getControl(this.C_HOLDER_REFERENCE_ID).setValue(value);
  }

  get insuredReferenceId(): string | null {
    return this.getControl(this.C_INSURED_REFERENCE_ID).value;
  }

  set insuredReferenceId(value: string | null) {
    this.getControl(this.C_INSURED_REFERENCE_ID).setValue(value);
  }

  get paymentTypeId(): string | null {
    return this.getControl(this.C_PAYMENT_TYPE_ID).value;
  }

  set paymentTypeId(value: string | null) {
    this.getControl(this.C_PAYMENT_TYPE_ID).setValue(value);
  }

  get durationId(): string | null {
    return this.getControl(this.C_DURATION_ID).value;
  }

  set durationId(value: string | null) {
    this.getControl(this.C_DURATION_ID).setValue(value);
  }

  get collectionManagerId(): string | null {
    return this.getControl(this.C_COLLECTION_MANAGER_ID).value;
  }

  set collectionManagerId(value: string | null) {
    this.getControl(this.C_COLLECTION_MANAGER_ID).setValue(value);
  }

  /* AutoComplete */

  get filteredRootPolicies(): Observable<Array<IPolicySearchResult>> {
    return this._filteredRootPolicies;
  }

  get filteredCollectionManagers(): Observable<Array<ICollectionManager>> {
    return this._filteredCollectionManagers;
  }

  private filterRootPolicies(value: string): Array<IPolicySearchResult> {
    return value !== ''
      ? this.rootPolicies.filter(x => `${x.policyNumber}-${x.riskDescription}`.toLowerCase().includes(value.toLowerCase()))
      : this.rootPolicies;
  }

  private filterCollectionManagers(value: string): Array<ICollectionManager> {
    return value !== ''
      ? this._collectionManagers.filter(c => c.name.toLowerCase().includes(value.toLowerCase()))
      : this._collectionManagers;
  }

  updaterResults: IPolicyReplacerFileItem[] = [];
  updaterResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.replacer.insuranceCompany.column:Compañía`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.replacer.policyNumber.column:Nº. Póliza`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.policies.replacer.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];
}
