import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileUploadComponent } from '@howdeniberia/core-front';
import { forkJoin, tap } from 'rxjs';
import { CollectionManagerStatus, PolicyClass, PolicyStatus } from 'src/app/core/enums';
import { IPolicyReplacerFileItem } from 'src/app/core/models/api/fleets';
import { IPolicySearchRequest } from 'src/app/core/models/api/policies';
import { IFleetService } from 'src/app/core/services/api/fleets';
import { IMasterService } from 'src/app/core/services/api/masters';
import { IPolicyService } from 'src/app/core/services/api/policies';
import { ISessionService } from 'src/app/core/services/storage/session';
import { IConfirmationDialogSrv, IUIBlockerService } from 'src/app/core/services/ui';
import { FormGroupUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { PolicyReplacerViewModel } from '../models';

@Component({
  selector: 'howden-policy-replacer',
  templateUrl: './policy-replacer.component.html',
  styleUrls: ['./policy-replacer.component.scss']
})
export class PolicyReplacerComponent implements OnInit, OnDestroy {
  model = new PolicyReplacerViewModel();

  get minDate(): Date { return Limits.minDate; }

  @ViewChild('file') fileRef?: FileUploadComponent;

  private _subscriptions = new SubSink();

  constructor(
    private uiBlockerSrv: IUIBlockerService,
    private confirmDialogSrv: IConfirmationDialogSrv,
    private sessionSrv: ISessionService,
    private fleetSrv: IFleetService,
    private masterSrv: IMasterService,
    private policySrv: IPolicyService
  ) {
  }

  ngOnInit(): void {
    const fleetId = this.sessionSrv.activeFleet;

    this.uiBlockerSrv.block();

    const policyFilter = {
      pageNumber: 0,
      fleetId: fleetId,
      policyClasses: [PolicyClass.Other.toString()],
      pageSize: Limits.maxPageSize,
      statuses: [PolicyStatus.Active.toString(), PolicyStatus.Request.toString()]
    } as IPolicySearchRequest;

    forkJoin([
      this.policySrv.search(policyFilter),
      this.masterSrv.getPaymentTypes(),
      this.masterSrv.getPolicyDurations(),
      this.masterSrv.getCollectionManagers(),
      this.policySrv.getTransferModes(),
      this.policySrv.getHolderReferences(),
      this.policySrv.getInsuredReferences()
    ]).subscribe(([
      rootPolicies,
      paymentTypes,
      policyDurations,
      collectionManagers,
      transferModes,
      holderReferences,
      insuredReferences
    ]) => {
      this.model.rootPolicies = rootPolicies.data;
      this.model.collectionManagers = collectionManagers.filter(x => x.status === CollectionManagerStatus.Active);
      this.model.paymentTypes = paymentTypes;
      this.model.policyDurations = policyDurations;
      this.model.transferModes = transferModes;
      this.model.holderReferences = holderReferences;
      this.model.insuredReferences = insuredReferences;

      this.model.transferModeId = transferModes[0].key;

      this.uiBlockerSrv.unblock();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onAccept() {
    const fleetId = this.sessionSrv.activeFleet;
    const request = FormGroupUtils.convertToFormData(this.model.form);

    this.uiBlockerSrv.block();

    this.fleetSrv.policyReplacer(fleetId, request).pipe(
      tap((response) => {
        this.uiBlockerSrv.unblock();

        if (response && response.updates && response.updates.length > 0) {
          this.model.updaterResults = response.updates;
        } else {
          const title = $localize`:@@app.policies.replacer.confirm.dialog.title:Traspaso/Reemplazo de pólizas`;
          const subtitle = $localize`:@@app.policies.replacer.confirm.dialog.subtitle:Finalizado correctamente, recuerde que no todos los cambios son efectivos de inmediato.`;

          this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => this.reset());
        }
      })
    ).subscribe();
  }

  onFileSelected(): void {
    this.model.form.patchValue({
      [this.model.C_FILE]: this.fileRef?.file
    });
  }

  getRootPolicyDescription(value: string): string {
    const rootPolicy = this.model.rootPolicies?.find(x => x.policyId === value);
    return rootPolicy ? `${rootPolicy.policyNumber}-${rootPolicy.riskDescription}` : '';
  }

  getCollectionManagerName(collectionManagerId: string): string {
    const collectionManager = this.model.collectionManagers?.find(x => x.collectionManagerId === collectionManagerId);
    return collectionManager ? `${collectionManager.alias}-${collectionManager.name}` : '';
  }

  get hasUpdaterResults(): boolean {
    return this.model.updaterResults && this.model.updaterResults.length > 0;
  }

  onGoBack(): void {
    this.reset();
  }

  private reset(): void {
    this.model.form.reset();
    this.fileRef?.clearFiles();
    this.model.cancelWithoutEffect = false;
    this.model.updaterResults = new Array<IPolicyReplacerFileItem>();
  }
}
